import GetCallbackForm from "components/LandingPage/GetCallbackForm";
import React from "react";

function Callback() {
  return (
    <section className="py-[40px] px-[16px] md:py-[60px] bg-[#D9D8EA]">
      <h2 className="font-bold text-[24px] md:text-[36px] text-[#1B1D6A] md:text-center">
        We&apos;re here for you
      </h2>
      <p className="text-[#646464] text-[14px] md:text-[20px] md:w-8/12 m-auto md:text-center pb-[34px]">
        Our loans expert will call you to explain the refinancing process.
      </p>
      <div className="max-w-[800px] mx-auto">
        <GetCallbackForm showEmploymentStatusField={false} />
      </div>
    </section>
  );
}

export default Callback;
